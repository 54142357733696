<template>
  <span>
    <b-button
      @click="
        () => {
          modalOne = !modalOne;
          this.loadContracts();
        }
      "
      variant="outline-primary"
      size="sm"
    >
      <feather-icon icon="SearchIcon" /> Search Contract
    </b-button>

    <b-modal v-model="modalOne" size="lg" centered title="Contract Search">
      <DxDataGrid
        id="gridContainer"
        ref="dxGridContainer"
        :data-source="dataSource"
        :focused-row-enabled="true"
        :allow-column-reordering="true"
        :allow-column-resizing="true"
        :column-auto-width="true"
        :show-borders="true"
        :row-alternation-enabled="true"
        key-expr="id"
      >
        <DxScrolling row-rendering-mode="virtual" />
        <DxPaging :page-size="10" />
        <DxPager
          :visible="true"
          :allowed-page-sizes="[10, 20, 50, 100]"
          display-mode="full"
          :show-page-size-selector="true"
          :show-info="true"
          :show-navigation-buttons="true"
        />

        <DxColumnFixing :enabled="true" />
        <DxSorting mode="multiple" />
        <DxFilterRow :visible="true" />
        <DxGroupPanel :visible="true" />
        <DxGrouping :auto-expand-all="true" />
        <DxHeaderFilter :visible="true" />
        <DxSearchPanel location="before" :visible="true" />

        <DxEditing
          :allow-updating="true"
          :allow-deleting="true"
          :use-icons="true"
          mode="row"
        />

        <DxColumn type="buttons">
          <DxButton icon="check" hint="Select" :onClick="selectedContract" />
        </DxColumn>

        <DxColumn
          :visible="item.visible"
          :show-in-column-chooser="item.showInColumndChooser"
          :data-field="item.field"
          :caption="item.caption"
          :format="item.format"
          :data-type="item.dataType"
          v-for="(item, index) in dataColumns"
          :key="index"
        />

        <DxToolbar>
          <DxItem name="searchPanel" />
        </DxToolbar>

        <DxMasterDetail :enabled="true" template="masterDetailTemplate" />
        <template #masterDetailTemplate="{ data }">
          <div class="">
            <div style="text-align: justify; white-space: normal">
              {{ getDescription(data) }}
            </div>
          </div>
        </template>
      </DxDataGrid>

      <!-- eslint-disable -->
      <template #modal-footer="{ close }">
        <b-button
          :disabled="isLoading"
          @click="onAdhoc()"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          Adhoc
        </b-button>
        <!-- <b-button
          :disabled="isLoading"
          @click="modalTwo = !modalTwo"
          variant="primary"
          class="bg-darken-4"
          size="sm"
        >
          <b-spinner small type="grow" v-if="isLoading"></b-spinner>
          More...
        </b-button> -->
      </template>
    </b-modal>
  </span>
</template>

<script>
import contractsApi from '@api/contract_headers';
import { queryParameters } from '@/schema';

export default {
  name: 'ContractSearch',
  props: {
    companyId: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    isLoading: false,
    modalOne: false,
    dataSource: [],
    formData: {
      id: 0,
      company_id: null,
      company_name: null,
      contract_type: null,
      contract_status: null,
      chargeable_type: 'H',
      contract_eligible: null,
      refnum: null,
      gst: null,
      gst_percent: null,
      currency_id: 'SGD',
      exchange_rate: null,
      description: null,
      details: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: 'Singapore',
      tel: null,
      mobile: null,
      email: null,
      designation: null,
      poc: null,
      fax: null,
      status: 'O',
    },
    dataColumns: [
      { caption: 'Contract Reference', field: 'refnum' },
      {
        caption: 'Start',
        field: 'preview_start',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      {
        caption: 'End',
        field: 'preview_end',
        dataType: 'datetime',
        format: 'dd/MM/yyyy hh:mm a',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'id',
        field: 'id',
      },
      {
        visible: false,
        showInColumndChooser: false,
        caption: 'type',
        field: 'type',
      },
    ],
  }),
  mounted() {},
  methods: {
    closeModal() {
      this.$nextTick(() => {
        this.formData = Object.assign(
          {},
          {
            id: 0,
            company_id: null,
            company_name: null,
            contract_type: null,
            contract_status: null,
            chargeable_type: 'H',
            contract_eligible: null,
            refnum: null,
            gst: null,
            gst_percent: null,
            currency_id: 'SGD',
            exchange_rate: null,
            description: null,
            details: null,
            addr1: null,
            addr2: null,
            postal: null,
            country: 'Singapore',
            tel: null,
            mobile: null,
            email: null,
            designation: null,
            poc: null,
            fax: null,
            status: 'O',
          }
        );
      });
    },
    loadContracts() {
      this.isLoading = true;
      const params = {
        sort: '-updated_at',
        page: { size: 10000 },
        status: queryParameters.status,
        filterExt: `company_id=${this.companyId}`,
      };

      contractsApi
        .list(params)
        .then(({ data }) => {
          for (const item of data) {
            item.preview_start = new Date(item.start_date * 1000);
            item.preview_end = new Date(item.end_date * 1000);
          }

          this.dataSource = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    selectedContract(e) {
      const { data } = e.row;

      console.log(data);

      if (data) {
        this.$emit('on-select', data);
        this.modalOne = false;
      }
    },
    onAdhoc() {
      this.$emit('on-select', {});
      this.modalOne = false;
    },
    getDescription(v) {
      const { data } = v;
      if (data) {
        const { description } = data;
        return description;
      }
    },
  },
};
</script>

<style scoped>
.right-side {
  position: absolute;
  right: 1px;
  top: 6px;
}

.logo {
  line-height: 48px;
}

.logo img {
  vertical-align: middle;
  margin: 0 5px;
}

.logo img:first-child {
  margin-right: 9px;
}

.dx-row.dx-data-row .employee {
  color: #bf4e6a;
  font-weight: bold;
}

#gridContainer {
  /* margin: 20px 0; */
  height: 440x;
}
</style>
